import React from "react";
import * as cx from "classnames/bind";
import s from "styles/admin.module.scss";
import { TopMenu, AdminContent } from "components";
import Image from "next/image";
import Link from "next/link";
import dynamic from "next/dynamic";

const SideMenu = dynamic(() => import("components/menus/SideMenu"), {
  ssr: false,
});

import NextNprogress from "nextjs-progressbar";

const AdminLayout = ({ children, ...title }) => {
  return (
    <React.Fragment>
      <NextNprogress options={{ showSpinner: false }} />
      <div className={cx("dasboard", s.dashboard)}>
        <SideMenu />
        <AdminContent {...title}>{children}</AdminContent>
      </div>
    </React.Fragment>
  );
};

export default AdminLayout;
