import React, { useState, useEffect, useRef } from "react";
import { useSession } from "hooks";

import * as cx from "classnames/bind";
import s from "styles/Home.module.scss";
import Script from "next/script";
import {
  ServicePopup,
  LoginModal,
  HomeMenu,
  HomeContent,
  Footer,
} from "components";
import algoliasearch from "algoliasearch";
import { InstantSearch } from "react-instantsearch";

import NextNprogress from "nextjs-progressbar";

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
);

const indexName = "cocktailclub-cocktails-en";

const ServiceLayout = ({ children, className, ...props }) => {
  const [loginModalState, setLoginModalState] = useState({
    show: false,
    index: 0,
  });
  const progressBar =
    props.progressBar !== undefined ? props.progressbar : true;
  const [showLegal, setShowLegal] = useState(false);

  const { data: session, status, mutate } = useSession();
  const loading = status === "loading";

  const cocktailsSearchRef = useRef(null);

  const setShowLoginModal = (show) => {
    setLoginModalState({
      index: 0,
      show,
    });
  };

  return (
    <React.Fragment>
      {progressBar && <NextNprogress options={{ showSpinner: false }} />}
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <div className={cx("Home", s.Home, className)} {...props}>
          <HomeMenu
            session={session}
            setLoginModalState={setLoginModalState}
            setShowLoginModal={setShowLoginModal}
            cocktailsSearchRef={cocktailsSearchRef}
          />
          <HomeContent>
            {React.Children.toArray(children).map((child) =>
              React.cloneElement(child, {
                ...props,
                loading,
                session,
                setShowLoginModal,
                setLoginModalState,
                cocktailsSearchRef,
              }),
            )}
          </HomeContent>
          <Footer />
          <ServicePopup />
        </div>
      </InstantSearch>
      <LoginModal
        onClose={() => setShowLoginModal(false)}
        state={loginModalState}
        setLoginModalState={setLoginModalState}
        title="Login or Sign up"
      />
    </React.Fragment>
  );
};

export default ServiceLayout;
