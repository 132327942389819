import * as cx from "classnames/bind";
import { Footer, Bar, HomeContent, HomeMenu, LoginModal } from "components";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import Script from "next/script";
import NextNprogress from "nextjs-progressbar";
import React, { useState, useEffect, useRef } from "react";
import { useSession } from "hooks";

import dynamic from "next/dynamic";

const LegalPopup = dynamic(() => import("../components/popups/LegalPopup.js"), {
  ssr: false,
});

import { hotjar } from "react-hotjar";
import s from "styles/Home.module.scss";
import Cookies from "js-cookie";
import store from "store2";
/*
import { InstantSearch } from "react-instantsearch-hooks";
import algoliasearch from "algoliasearch";

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY
);

const indexName = "cocktailclub-cocktails-en";
*/

const HomeLayout = ({ children, className, progressbar = true, ...props }) => {
  const [loginModalState, setLoginModalState] = useState({
    show: false,
    index: 0,
  });

  const cocktail = children?.props?.cocktail;
  const progressBar = !!progressbar;
  const [showLegal, setShowLegal] = useState(false);

  const { data: session, status, mutate } = useSession();
  const loading = status === "loading";

  const cocktailsSearchRef = useRef(null);

  const setShowLoginModal = (show) => {
    setLoginModalState({
      index: 0,
      show,
    });
  };
  useEffect(() => {
    hotjar.initialize(1691264, 6);
  }, []);

  const router = useRouter();
  useEffect(() => {
    async function decodeToken() {
      const url = `${process.env.NEXT_PUBLIC_REST_API_URL}auth/decode`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": process.env.NEXT_PUBLIC_API_KEY,
        },
        body: JSON.stringify(router.query),
      });
      const data = await response.json();
      if (data.password) {
        const response = await signIn("credentials", {
          email: router.query.email,
          password: data.password,
          redirect: false,
        });
        mutate();
        setShowLegal(!!!response.ok);
        Cookies.set("CookieConsent", response.ok);
      } else {
        setShowLegal(true);
      }
      store.session("inAppChecked", true);
    }
    if (router.isReady && status !== "loading") {
      if (
        !!router.query.token &&
        !!router.query.email &&
        status !== "loading"
      ) {
        store.session("inApp", true);
        const params = new URLSearchParams(router.query);
        params.delete("callbackUrl");
        params.delete("token");
        params.delete("email");
        router.replace(
          { pathname: router.pathname, query: params.toString() },
          undefined,
          { shallow: true },
        );
        decodeToken();
      } else {
        setShowLegal(!!!(session || {}).user);
        store.session("inAppChecked", true);
      }
    }
  }, [router.isReady, router.query, status]);

  return (
    <React.Fragment>
      {progressBar && <NextNprogress options={{ showSpinner: false }} />}
      <div className={cx("Home", s.Home, className)} {...props}>
        <HomeMenu
          session={session}
          setLoginModalState={setLoginModalState}
          setShowLoginModal={setShowLoginModal}
          cocktailsSearchRef={cocktailsSearchRef}
        />
        <HomeContent>
          {React.Children.toArray(children).map((child) =>
            React.cloneElement(child, {
              ...props,
              loading,
              session,
              setShowLoginModal,
              setLoginModalState,
              cocktailsSearchRef,
            }),
          )}
        </HomeContent>
        <Footer />
        {showLegal && <LegalPopup />}
      </div>
      <LoginModal
        onClose={() => setShowLoginModal(false)}
        state={loginModalState}
        setLoginModalState={setLoginModalState}
        title="Login or Sign up"
        cocktail={cocktail}
      />
    </React.Fragment>
  );
};

export default HomeLayout;
