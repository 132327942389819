import React from "react";
import * as cx from "classnames/bind";
import s from "styles/admin.module.scss";
import { TopMenu } from "components";
import HorecaContent from "components/atoms/HorecaContent";
import Image from "next/image";
import Link from "next/link";
import dynamic from "next/dynamic";

import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
  ApolloProvider,
} from "@apollo/client";

import { WebSocketLink } from "@apollo/client/link/ws";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";

import { createClient } from "graphql-ws";

import { getMainDefinition } from "@apollo/client/utilities";

const HorecaMenu = dynamic(() => import("components/menus/HorecaMenu"), {
  ssr: false,
});

import NextNprogress from "nextjs-progressbar";

const useApolloClient = (headers) => {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    headers,
  });
  /*
  const wsLink =
    typeof window !== "undefined"
      ? new WebSocketLink( {
          uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
          options: {
            reconnect: true,
            connectionParams: { headers },
          },
        })
      : null;
      */

  const wsLink =
    typeof window !== "undefined"
      ? new GraphQLWsLink(
          createClient({
            url: process.env.NEXT_PUBLIC_GRAPHQL_URL,
            connectionParams: { headers },
          }),
        )
      : null;
  const link =
    typeof window !== "undefined" && wsLink != null
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === "OperationDefinition" &&
              definition.operation === "subscription"
            );
          },
          wsLink,
          httpLink,
        )
      : httpLink;

  const [client, setClient] = React.useState(
    new ApolloClient({
      link: link,
      cache: new InMemoryCache(),
    }),
  );
  return client;
};

const HorecaLayout = ({ children, ...other }) => {
  const apolloClient = useApolloClient(other.headers);
  return (
    <ApolloProvider client={apolloClient}>
      <NextNprogress options={{ showSpinner: false }} />
      <div className={cx("dasboard", s.dashboard)}>
        <HorecaMenu {...{ ...other, apolloClient }} />
        <HorecaContent {...{ ...other, apolloClient }}>
          {children}
        </HorecaContent>
      </div>
    </ApolloProvider>
  );
};

export default HorecaLayout;
