import { motion } from "framer-motion";
import * as cx from "classnames/bind";
import {
  Button,
  ButtonLink,
  Container,
  Section,
  ScrollX,
  Wistia,
  ArticleCard,
  CollectionCard,
} from "components";
import { HomeLayout } from "layouts";
import Image from "next/legacy/image";
import Link from "next/link";
import Script from "next/script";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useState } from "react";
import { runQuery } from "queries";
import { useInApp } from "hooks";
import g from "styles/Gallery.module.scss";
import s from "styles/Home.module.scss";

import { useGQLSWR, fetcher, imageUrl } from "utils";
import { request, gql } from "graphql-request";

const Home = ({
  cocktails,
  articles,
  collections,
  setShowLoginModal,
  setLoginModalState,
  ...props
}) => {
  const { t } = useTranslation();
  const regex = /(<([^>]+)>)/gi;
  const isInApp = useInApp();

  return (
    <>
      <Section id="homeTop">
        <Container className="fullWidth">
          <div className={cx(s.homeBanner)}>
            <div className={cx("videoDisplayMob", s.videoDisplayMob)}>
              <Wistia code="3cskugg9re" size="177.71% 0 0 0" />
            </div>
            <div className={cx("videoDisplay", s.videoDisplay)}>
              <Wistia code="h6pw70dvz3" size="56.25% 0 0 0" />
            </div>
            <Container className={cx(s.homeContainer)}>
              <div className={cx("textDisplay", s.textDisplay)}>
                <h1 className="White">
                  {t("SCAN, MIX AND SERVE.")}
                  <br />
                  {t("INSPIRATIONAL HOME BARTENDING.")}
                </h1>
                <div className="ButtonGroup">
                  <ButtonLink
                    href="/cocktails"
                    size="fas"
                    svg="cocktail"
                    className="Primary Blue Rad50 Shadow"
                    title={t("Explore cocktails")}
                  >
                    {t("Explore cocktails")}
                  </ButtonLink>
                  <Button
                    size="fas"
                    svg="stars"
                    className="Primary Cyan Rad50 Shadow hidden"
                    onClick={() => setLoginModalState({ show: true, index: 1 })}
                    title={t("Join the club")}
                  >
                    {t("Join the club")}
                  </Button>
                  <a
                    size="fas"
                    svg="stars"
                    className="Button Primary Rad50 Shadow"
                    href="https://cocktailclubtools.com/"
                    title={t("Shop bar tools")}
                    target="_blank"
                  >
                    {t("Shop bar tools")}
                  </a>
                </div>
              </div>
              {!isInApp && (
                <div className={cx("appDisplay", s.appDisplay)}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.app.cocktailclub20"
                    target="_blank"
                    rel="noreferrer"
                    id="btn-android"
                    title="Play store"
                  >
                    <Image
                      src="/img/playstore.png"
                      width={200}
                      height={59}
                      alt="Play store"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/no/app/cocktail-club-drink-recipes/id1599292471"
                    target="_blank"
                    rel="noreferrer"
                    id="btn-ios"
                    title="App store"
                  >
                    <Image
                      src="/img/appstore.png"
                      width={200}
                      height={59}
                      alt="App store"
                    />
                  </a>
                </div>
              )}
            </Container>
          </div>
          {/*
          <video
            autoPlay
            playsInline
            muted
            loop
            data-matomo-title="Frontpage intro video"
            title="Cocktail Club - Introduction"
            className="m__auto mb16 hidden"
          >
            <source
              src={`${process.env.NEXT_PUBLIC_S3_BUCKET}vid/pages/cocktailclub.mp4`}
              type="video/mp4"
            />
          </video>
          */}
        </Container>
      </Section>
      <Section className={cx(s.exploreSection)}>
        <Container className="">
          <div className={cx(s.exploreHeader)}>
            <h2 className="Heading mb8">{t("Explore cocktail collections")}</h2>
            <ButtonLink
              svg="chevron-right"
              size="far"
              href="/cocktails/collections"
              className={cx(s.exploreBtn)}
            >
              {t("View all collections")}
            </ButtonLink>
          </div>
          <motion.div initial="hidden" animate="show">
            <ScrollX
              className={cx(
                "GalleryFeed",
                g.GalleryFeed,
                `i-${
                  (collections || []).filter(
                    (collection) => collection.featured === true,
                  ).length
                }`,
              )}
            >
              {/* {(articles || []).map((article) => (*/}
              {(collections || [])
                .filter((collection) => collection.featured === true)
                .map((collection) => (
                  <CollectionCard
                    className={cx("rowItemScroll__L")}
                    key={collection.id}
                    collection={collection}
                    transition={{
                      duration: 0.33,
                      ease: "easeInOut",
                    }}
                    {...props}
                  />
                ))}
            </ScrollX>
          </motion.div>
        </Container>
      </Section>
      <Section>
        <Container>
          <div className="flex flex-wrap">
            <div className="split-item-50__d">
              <div className="imgRes">
                <Image
                  src="/img/qkit1.jpg"
                  objectFit="cover"
                  layout="responsive"
                  height={900}
                  width={900}
                  alt="Cocktail Kit"
                />
              </div>
            </div>
            <div
              className="split-item-50__d flex align-center p32"
              style={{ background: "#ecf8ff" }}
            >
              <div className="contentInner">
                <h4 className="Heading">{t("Bar set")}</h4>
                <div className="HeadingGroup">
                  <h5 className="Heading__sub">
                    {t("Our complete cocktail kit solution.")}
                  </h5>
                  <h5 className="Heading__sub">
                    {t("Everything needed to be your own bartender.")}
                  </h5>
                  <h5 className="Heading__sub">
                    {t("Including full access to recipe tutorials")}
                  </h5>
                </div>
                <p className="Cyan">{t("Free shipping")}</p>
                <p className="Cyan">{t("3 - 5 days delivery")}</p>
                <hr />
                <p>
                  {t(
                    "We have worked hard to give you the most comprehensive cocktail making solution on the market – based on our step-by-step video recipes.",
                  )}
                </p>
                <ButtonLink
                  href="https://cocktailclubtools.com/"
                  className="Primary"
                  size="far"
                  svg="chevron-right"
                >
                  {t("Shop bar tools")}
                </ButtonLink>
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className="div">
            <div className={cx("videoDisplayMob", s.videoDisplayMob)}>
              <Wistia code="hyas8zjaoj" size="56.25% 0 0 0" />
            </div>
            <div className={cx("videoDisplay", s.videoDisplay)}>
              <Wistia code="hyas8zjaoj" size="56.25% 0 0 0" />
            </div>
          </div>
        </Container>
      </Section>
      <Section className={cx(s.exploreSection)}>
        <Container>
          <div className={cx(s.exploreHeader)}>
            <h2 className="Heading mb8">{t("Explore featured articles")}</h2>
            <ButtonLink
              svg="chevron-right"
              size="far"
              href="/journal/"
              className={cx(s.exploreBtn)}
            >
              {t("View all articles")}
            </ButtonLink>
          </div>
          <motion.div initial="hidden" animate="show">
            <ScrollX
              className={cx(
                "GalleryFeed",
                g.GalleryFeed,
                `i-${
                  (articles || []).filter(
                    (article) => article.featured === true,
                  ).length
                }`,
              )}
            >
              {/* {(articles || []).map((article) => (*/}
              {(articles || [])
                .filter((article) => article.featured === true)
                .map((article) => (
                  <ArticleCard
                    className={cx("rowItemScroll__L")}
                    key={article.id}
                    article={article}
                    transition={{
                      duration: 0.33,
                      ease: "easeInOut",
                    }}
                    {...props}
                  />
                ))}
            </ScrollX>
          </motion.div>
        </Container>
      </Section>
      <Section className={cx(s.exploreSection, "hidden")}>
        <Container>
          <div className={cx(s.exploreHeader)}>
            <h2 className="Heading mb8">{t("Explore our courses")}</h2>
            <ButtonLink
              svg="chevron-right"
              size="far"
              href="/courses/"
              className={cx(s.exploreBtn)}
            >
              {t("View all courses")}
            </ButtonLink>
          </div>
          <motion.div initial="hidden" animate="show">
            <ScrollX
              className={cx("GalleryFeed i-4", g.GalleryFeed, g.CourseFeed)}
            >
              <div className={cx("rowItem rowItemScroll__L", g.CourseItem)}>
                <div>
                  <div
                    className={cx(
                      "GalleryImageWrapper Rad ofh p0",
                      g.CourseImageWrapper,
                    )}
                  >
                    <Image
                      src="/img/courses/course1.jpg"
                      layout="intrinsic"
                      height={600}
                      width={900}
                      alt="Cocktail Workshop"
                      objectFit="cover"
                    />
                  </div>
                  <div className={cx(g.CourseInfo)}>
                    <h3 className="Heading__sub Bold mb8">
                      {t("Cocktail Workshop")}
                    </h3>
                    <p>
                      {t("3 recipies choosen by participants.")}
                      <br />
                      {t(
                        "Cocktail Workshop is fun and easy way of exploring cocktails...",
                      )}
                    </p>
                    <p className="Heading__price Heading__small Blue mb8">
                      75 EUR /<br />
                      750 NOK PP
                    </p>
                    <ButtonLink
                      href="/courses"
                      className={cx("Primary Cyan Rad50 Shadow")}
                    >
                      {t("Read more")}
                    </ButtonLink>
                  </div>
                </div>
              </div>
              <div className={cx("rowItem rowItemScroll__L", g.CourseItem)}>
                <div>
                  <div
                    className={cx(
                      "GalleryImageWrapper Rad ofh p0",
                      g.CourseImageWrapper,
                    )}
                  >
                    <Image
                      src="/img/courses/course3.jpg"
                      layout="intrinsic"
                      height={600}
                      width={900}
                      alt="4Steps2Heaven"
                      objectFit="cover"
                    />
                  </div>
                  <div className={cx(g.CourseInfo)}>
                    <h3 className="Heading__sub Bold mb8">
                      {t("4Steps2Heaven")}
                    </h3>
                    <p>
                      {t("5 tastingsamples (3 units in total).")}
                      <br />
                      {t("Cocktail Club will give you a tasting that makes...")}
                    </p>
                    <p className="Heading__price Heading__small Blue mb8">
                      75 EUR /<br />
                      750 NOK PP
                    </p>
                    <ButtonLink
                      href="/courses"
                      className={cx("Primary Cyan Rad50 Shadow")}
                    >
                      {t("Read more")}
                    </ButtonLink>
                  </div>
                </div>
              </div>
              <div className={cx("rowItem rowItemScroll__L", g.CourseItem)}>
                <div>
                  <div
                    className={cx(
                      "GalleryImageWrapper Rad ofh p0",
                      g.CourseImageWrapper,
                    )}
                  >
                    <Image
                      src="/img/courses/course2.jpg"
                      layout="intrinsic"
                      height={600}
                      width={900}
                      alt="Mystery Box"
                      objectFit="cover"
                    />
                  </div>
                  <div className={cx(g.CourseInfo)}>
                    <h3 className="Heading__sub Bold mb8">
                      {t("Mystery Box")}
                    </h3>
                    <p>
                      {t("3 units in total.")}
                      <br />
                      {t(
                        "Participants are divided into groups and given a box...",
                      )}
                    </p>
                    <p className="Heading__price Heading__small Blue mb8">
                      75 EUR /<br />
                      750 NOK PP
                    </p>
                    <ButtonLink
                      href="/courses"
                      className={cx("Primary Cyan Rad50 Shadow")}
                    >
                      {t("Read more")}
                    </ButtonLink>
                  </div>
                </div>
              </div>
              <div className={cx("rowItem rowItemScroll__L", g.CourseItem)}>
                <div>
                  <div
                    className={cx(
                      "GalleryImageWrapper Rad ofh p0",
                      g.CourseImageWrapper,
                    )}
                  >
                    <Image
                      src="/img/courses/course4.jpg"
                      layout="intrinsic"
                      height={600}
                      width={900}
                      alt="Combinations"
                      objectFit="cover"
                    />
                  </div>
                  <div className={cx(g.CourseInfo)}>
                    <h3 className="Heading__sub Bold mb8">
                      {t("Combinations")}
                    </h3>
                    <p>
                      {t("4steps2Heaven")} + {t("Mystery Box")}
                    </p>
                    <p>
                      {t("4steps2Heaven")} + {t("Cocktail Workshop")}
                    </p>
                    <p className="Heading__price Heading__small Blue mb8">
                      125 EUR /<br />
                      1250 NOK PP
                    </p>
                    <ButtonLink
                      href="/courses"
                      className={cx("Primary Cyan Rad50 Shadow")}
                    >
                      {t("Read more")}
                    </ButtonLink>
                  </div>
                </div>
              </div>
            </ScrollX>
          </motion.div>
        </Container>
      </Section>
      <Section>
        <Container>
          <div className="flex flex-wrap row-reverse__d">
            <div className="split-item-50__d">
              <div className="imgRes">
                <Image
                  src="/img/get_started.jpg"
                  objectFit="cover"
                  layout="responsive"
                  height={900}
                  width={900}
                  alt="Get Started"
                />
              </div>
            </div>
            <div
              className="split-item-50__d flex align-center p32"
              style={{ background: "#ffffff" }}
            >
              <div className="contentInner">
                <h4 className="Heading">{t("Need help getting started?")}</h4>
                <p>
                  {t(
                    "So, you are somewhat ready to step into the world of home bartending? Before you invite some of your friends over, take a few minutes to read our tips & tricks and prepare yourself as any pro would.",
                  )}
                </p>
                <p>
                  {t(
                    "You should also prepare or buy some of the ingredients that are often considered as seen upon as the basics in all cocktail mixings.",
                  )}
                </p>
                <ButtonLink
                  href="/journal/getting-started"
                  className="Primary Hollow"
                >
                  {t("Let us help you")}
                </ButtonLink>
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className="flex flex-wrap">
            <div className="split-item-50__d">
              <div className="imgRes">
                <Image
                  src="/img/forall.jpg"
                  objectFit="cover"
                  layout="responsive"
                  height={900}
                  width={900}
                  alt="For everyone"
                />
              </div>
            </div>
            <div
              className="split-item-50__d flex align-center p32"
              style={{ background: "#ffffff" }}
            >
              <div className="contentInner">
                <h4 className="Heading">
                  {t("Cocktail recipes for all tastes")}
                </h4>
                <p>
                  {t(
                    "We have worked hard to give you the best recipes of the most favored and loved cocktails around the world. Here you’ll find recipes with easy step-by-step tutorials that will make you mix cocktails like a pro in minutes.",
                  )}
                </p>
                <ButtonLink
                  href="/cocktails"
                  className="Primary Pink"
                  size="far"
                  svg="chevron-right"
                >
                  {t("Explore recipes")}
                </ButtonLink>
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <Section>
        <Container className="center">
          <h2 className="Heading">
            {t("Popular and easy to learn cocktails")}
          </h2>
          <div className={cx("GalleryFeed row", g.GalleryFeed)}>
            <div className={cx("GalleryFeedItem rowItem", g.GalleryFeedItem)}>
              <button className="favBtn"></button>
              <Link href="/cocktails/espresso-martini">
                <Image
                  src="/img/cocktails/espressoMartini.jpg"
                  layout="intrinsic"
                  height={900}
                  width={700}
                  alt="Espresso"
                />
                <header>
                  <p>Vodka</p>
                  <h5 className="title">Espresso Martini</h5>
                  <span>4 mins / {t("Easy")}</span>
                </header>
              </Link>
            </div>
            <div className={cx("GalleryFeedItem rowItem", g.GalleryFeedItem)}>
              <button className="favBtn"></button>
              <Link href="/cocktails/shirley-temple">
                <Image
                  src="/img/cocktails/shirleyTemple.jpg"
                  layout="intrinsic"
                  height={900}
                  width={700}
                  alt="Shirley"
                />
                <header>
                  <p>Non-alc</p>
                  <h5 className="title">Shirley Temple</h5>
                  <span>2 mins / {t("Very easy")}</span>
                </header>
              </Link>
            </div>
            <div className={cx("GalleryFeedItem rowItem", g.GalleryFeedItem)}>
              <button className="favBtn"></button>
              <Link href="/cocktails/pink-lady">
                <Image
                  src="/img/cocktails/pinkLady.jpg"
                  layout="intrinsic"
                  height={900}
                  width={700}
                  alt="PinkLady"
                />
                <header>
                  <p>Gin</p>
                  <h5 className="title">Pink Lady</h5>
                  <span>3 mins / {t("Intermediate")}</span>
                </header>
              </Link>
            </div>
            <div className={cx("GalleryFeedItem rowItem", g.GalleryFeedItem)}>
              <button className="favBtn"></button>
              <Link href="/cocktails/frozen-margarita">
                <Image
                  src="/img/cocktails/frozenMargarita.jpg"
                  layout="intrinsic"
                  height={900}
                  width={700}
                  alt="Margarita"
                />
                <header>
                  <p>Tequila</p>
                  <h5 className="title">Frozen Margarita</h5>
                  <span>4 mins / {t("Easy")}</span>
                </header>
              </Link>
            </div>
          </div>
        </Container>
      </Section>
    </>
  );
};

Home.getLayout = (page) => {
  return <HomeLayout style={{ paddingTop: "0px" }}>{page}</HomeLayout>;
};

export const getStaticProps = async ({ locale }) => {
  const ids = [1, 2, 3];
  const query = gql`
    query {
      articles(where: { hidden: { _eq: false } }, order_by: { id: desc }) {
        id
        title
        slug
        translations
        excerpt
        image_id
        hidden
        featured
        categories {
          category {
            name
            slug
            translations
          }
        }
      }
      categories: article_categories(
        where: {
          _not: { articles_aggregate: { count: { predicate: { _eq: 0 } } } }
        }
      ) {
        id
        name
        slug
      }
      collections: categories(
        where: { hidden: { _eq: false } }
        order_by: { id: desc }
      ) {
        id
        name
        translations
        slug
        hidden
        featured
        image_id
        cocktail_categories_aggregate(
          where: { cocktail: { _not: { hidden: { _eq: true } } } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `;
  return {
    props: {
      ...(await serverSideTranslations(locale)),
      ...(await fetcher(query)),
      ...(
        await runQuery("cocktails/multiple", {
          filter: `{id: {_in: [${ids.join(",")}]}}`,
        })
      ).props,
    },
    revalidate: 60,
  };
};

export default Home;
